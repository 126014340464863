.article-cover {
  background-color: #f5f5f5;
  padding: 20px 20px;
}
.article-main {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 20px;
  width: 100%;
  gap: 15px;
  max-width: 1400px;
  min-height: 100vh;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 4px 4px 6px 4px #e2e8f0;
}
.article-1:hover,
.article-2:hover {
  cursor: pointer;
}
.article-content > h1 {
  font-size: 1.8rem;
}
.article-img {
  width: 60%;
  height: 60%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.article-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .article-cover {
    padding: 0px;
  }
  .article-main {
    background-color: #f5f5f5;
  }
  .article-img {
    width: 100%;
    height: 100%;
  }
}

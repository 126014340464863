.contactHeader {
  font-size: 2.8rem;
  height: 260px;
  margin-bottom: 2.5rem;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  letter-spacing: 0.7px;
  background-image: url("../Assets/contactus.png");
  background-size: cover;
  background-position: center;
}

.contactHeader h1 {
  color: #fff;
  justify-content: center;
  padding-top: 90px;
}

.contact-title-sup {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-top: 12px;
}
.contact-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.contact-title > span {
  position: relative;
}
.contact-title span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #9d2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.contact-title-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.dotted-line {
  /* position: absolute; */
  width: 750px;
  height: 25px; /* Adjust height as needed */
  border-bottom: 3px dotted #9d2553; /* Add dotted border */
  margin: 10px auto; /* Center the line */
}

/* Responsive */

@media screen and (max-width: 1200px) {
  .contactHeader h1 {
    font-size: 1.6em;
  }
}
@media screen and (max-width: 968px) {
  .contactHeader h1 {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 688px) {
  .contactHeader {
    height: 245px;
  }
  .contactHeader h1 {
    padding-top: 80px;
    font-size: 1.3em;
  }
}

.navbar-section {
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: white;
}
.navbar-title a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a8efd;
  letter-spacing: 0.6px;
  text-decoration: none;
  padding-bottom: 30px;
}
.navbar-title {
  font-family: "Poppins", sans-serif;
}
.navbar-sign {
  color: #54de54;
  font-family: Cambria, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.navbar-items {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  font-family: "Rubik", sans-serif;
}
.navbar-items > li {
  list-style-type: none;
}
.navbar-links {
  text-decoration: none;
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.navbar-links:hover {
  color: #0cc2ea;
}
.navbar-btn {
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #1a8efd;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.navbar-btn:hover {
  color: #1a8efd;
  background-color: white;
  border: 1px solid #1a8efd;
}
.subnav {
  list-style-type: none;
  gap: 12px;
  position: absolute;
  top: 50px;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.subnav li {
  padding: 10px 22px;
  text-align: left;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  color: black;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
}

.subnav li a {
  text-decoration: none; /* Remove underline */
  color: black; /* Change link color */
}

.subnav li a:hover {
  color: #0cc2ea; /* Change link color on hover */
}

/* Hamburger Icon */
.mobile-nav {
  display: none;
}
.hamb-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.hamb-icon:hover {
  color: #0cc2ea;
}
/* Mobile Navbar */
.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: white;
  z-index: 20;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease-in-out;
}
.mobile-navbar-close {
  position: absolute;
  top: 28px;
  right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
  color: rgb(255, 22, 22);
}
.open-nav {
  left: 0;
}
.mobile-navbar-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 24px;
  text-align: left;
}
.mobile-navbar-links li a {
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  transition: color 0.3s ease-in-out;
}
.mobile-navbar-links li a:hover {
  color: #0cc2ea;
}
.mobile-navbar-links-sub {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 20px;
  text-align: left;
  margin-left: 35px;
  margin-top: 5px;
}
.mobile-navbar-links-sub li a {
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
  /* font-weight: bold; */
  letter-spacing: 0.8px;
  transition: color 0.3s ease-in-out;
}
.mobile-navbar-links-sub li a:hover {
  color: #0cc2ea;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .navbar-section {
    padding: 0 10px;
    height: 60px;
  }
  .navbar-title a {
    letter-spacing: 0.2px;
    padding-bottom: 1px;
  }
  .navbar-sign {
    font-size: 38px;
  }
  .navbar-btn,
  .navbar-items {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}

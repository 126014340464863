/* Form */
.form-content label {
  margin: 0 0 16px 0;
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  letter-spacing: 0.7px;
}
.form-content label::before {
  content: "*";
  color: red;
  padding: 0 4px 0 0;
}
.form-content label :is(input, select) {
  width: 100%;
  margin: 8px 0;
  padding: 0 12px;
  display: block;
  height: 40px;
  color: black;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #c9def4;
  outline: transparent;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.7px;
}
/* Success Message */
.success-message {
  margin: 20px 0 0 0;
  color: green;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  letter-spacing: 0.7px;
  line-height: 1.7rem;
}
/* Error Message */
.error-message {
  color: red;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  letter-spacing: 0.7px;
  line-height: 1.7rem;
}
/* Responsive */
@media screen and (max-width: 600px) {
  .form-container {
    padding: 24px;
  }
  .form-title {
    font-size: 24px;
  }
  .form-content label {
    font-size: 18px;
  }
  .form-content label :is(input, select) {
    font-size: 16px;
  }
  .success-message {
    margin: 12px 0 0 0;
    font-size: 18px;
  }
}

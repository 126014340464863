.article1-cover {
  background-color: #f5f5f5;
  padding: 20px 20px;

  .article1-main {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 20px;
    width: 100%;
    gap: 15px;
    max-width: 1400px;
    min-height: 100vh;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 6px 4px #e2e8f0;
  }

  .article1-img {
    width: 100%;
    height: 500px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .article2-img {
    width: 100%;
    height: 400px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .article1-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .article2a-img {
    width: 100%;
    /* height: 500px; */
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  .article2a-img img {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #2d3748;
  }
  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #2d3748;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
  }
  h4 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #2d3748;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000000;
    margin-top: 2px;
    margin-bottom: 4px;
  }
  p {
    font-size: 1.1rem;
    color: #4a5568;
  }
  ol {
    font-size: 1.2rem;
    color: #4a5568;
    li {
      font-size: 1.2rem;
      font-weight: 1000;
      margin: 0px;
      line-height: 1.1;
      list-style: none;
      &::before {
        content: "▹";
        color: #2d3748;
        font-size: 2rem;
        font-weight: 800;
        margin-right: 4px;
      }
    }
    ul {
      font-size: 1.2rem;
      color: #4a5568;
      margin: 2px 8px;
      li {
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
    p {
      margin: 8px 28px;
    }
  }
  ul {
    font-size: 1.4rem;
    color: #4a5568;
    li {
      font-size: 1.2rem;
      color: #4a5568;
      margin: 8px 28px;
    }
  }
  li {
    margin: 8px 28px;
  }
  /* Responsive */
}
@media screen and (max-width: 700px) {
  .article1-cover {
    padding: 0px;
    .article1-main {
      background-color: #f5f5f5;
    }
    .article1-img {
      width: 100%;
      height: 100%;
    }
    .article2-img {
      width: 100%;
      height: 90%;
    }
    .article2-img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .article2a-img {
      width: 100%;
      height: 100%;
    }
    .article2a-img img {
      margin: 10px;
    }
    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      color: #2d3748;
    }
    h2 {
      font-size: 2rem;
      font-weight: 600;
      color: #2d3748;
    }
    h3 {
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: #2d3748;
    }
    h5 {
      font-size: 1.1rem;
      margin-top: 2px;
      margin-bottom: 2px;
    }
    p {
      font-size: 1rem;
      color: #4a5568;
    }
    ol {
      font-size: 1.2rem;
      color: #4a5568;
      li {
        font-size: 1.2rem;
        margin: 0px;
        line-height: 1.2;
        list-style: none;
        &::before {
          content: "▹";
          color: #2d3748;
          font-size: 1.6rem;
          font-weight: 800;
          margin-right: 14px;
        }
      }
      ul {
        font-size: 1.2rem;
        color: #4a5568;
        margin: -2px;
        li {
          font-weight: 500;
          font-size: 1.05rem;
        }
      }
      p {
        margin: 2px 22px;
      }
    }
    ul {
      li {
        font-size: 1rem;
        color: #4a5568;
        margin: 8px 28px;
      }
    }
  }
}

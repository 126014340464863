.info-section {
  padding: 0 32px;
  text-align: center;
  background-color: white;
}
.info-title-content {
  margin: 64px 0 128px 0;
}
.info-title-sup {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.info-title {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.info-title > span {
  position: relative;
}
.info-title span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #9d2553;
  border-radius: 8px;
  left: 0;
  bottom: -12px;
}
.info-description {
  margin: 44px;
  text-align: center;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.8rem;
}
/* Cards */
.info-card-img {
  background-color: #ffcaa6;
  width: 95%;
  margin: auto;
  margin-top: 10px;
  padding-top: 62%; /* Increased aspect ratio for the image */
  background-size: 100%;
  background-position: contain;
  border-radius: 0.5rem; /* Added rounded corners */
  transition: 0.3s ease;
}
/* .info-card-img:hover {
  transform: scale(25);
  box-shadow: #bee3f8 0px 0px 0px 0px;
} */
.info-cards-content {
  margin: 44px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}
.info-cards {
  height: auto;
  border: 0.02px solid #d7d3d37c;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.info-cards:hover .info-card-img {
  transform: translateY(-2%) scale(1.15);
  box-shadow: #bee3f8 0px 5px 7px -2px;
  transition: box-shadow 0.001s ease;
}
.info-cards:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease;
}
.info-card-title {
  margin: 22px 0 20px 0;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.6px;
}
.info-card-description {
  margin: 20px;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 1.3rem;
}
.info-card-icon {
  position: absolute;
  top: -24px;
  left: 44%;
  padding: 10px;
  background-color: #dce6ff;
  border-radius: 30px;
}
.info-fa-icon {
  color: #2c96ff;
  font-size: 24px;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .info-description {
    margin: 64px 28px;
  }
  .info-cards-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) {
  .info-title-content {
    margin: 44px 0 48px 0;
  }
  .info-description {
    margin: 64px 2px;
  }
  .info-cards-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .info-card-title {
    margin: 14px 1.8px;
    font-size: 24px;
    letter-spacing: 0.8px;
  }
  .info-card-description {
    margin: 16px;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 1.1rem;
  }
  .info-cards:hover .info-card-img {
    transform: translateY(-1%) scale(1.02);
  }
}

.container {
  width: 312px;
  text-align: left;
}
.card {
  overflow: visible;
  width: 100%;
  height: 302px;
  background: #862b2b;
  border-radius: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.card:before,
.card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background: #2193ff;
  transition: 0.5s;
  z-index: -99;
}

/*Image*/
.imgbox {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50px;
  bottom: 15px;
  right: 15px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
}

.img {
  background: #4158d0;
  position: absolute;
  top: 0;
  border-radius: 12px;
  left: 0;
  width: 100%;
  height: 100%;
}

/*Text*/

.title {
  margin: -28px 0 0 0;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.7px;
}

.caption {
  height: 54px;
  margin: -12px 0 22px;
  color: #494949;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  letter-spacing: 0.7px;
}

/*Hover*/
.card:hover .img {
  transition: 0.5s;
}

.card:hover:before {
  transform: rotate(20deg);
}

.card:hover:after {
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.mt-cover {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #f3f7ff;
  p {
    font-size: 0.9rem;
  }
}
.mt-head {
  padding: 15px;
}
.mt-h1 {
  font-size: 2.4rem;
  font-weight: 800;
  margin: 20px 0;
  text-align: center;
}
.mt-h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 20px 0;
  text-align: center;
}
.mt-h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 20px 0;
  text-align: center;
}
.mt-head-img {
  width: 100%;
  height: 420px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt-head-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.mt-head-below {
  background-color: #ffffff;
  padding: 15px;
}
.mt-third {
  padding: 15px;
}
.mt-fourth {
  padding: 15px;
  background-color: #ffffff;
  ul {
    padding-top: 10px;
    padding-left: 30px;
    h4 {
      line-height: 32px;
    }
  }
}
.mt-table1 {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  box-shadow: 2px 2px 2px #e2e8f0;
  background-color: #ffffff;
}
.mt-img-section {
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.mt-img-left {
  width: 60%;
}
.mt-img-right {
  width: 40%;
  margin-top: -900px;
}
.mt-img-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.mt-img-left {
  line-height: 34px;
}
.mt-img-left ul {
  padding-left: 20px;
}
@media (max-width: 700px) {
  .mt-cover {
    padding: 5px 0px;
  }
  .mt-cover p {
    font-size: 0.8rem;
    padding: 10px 0 10px 0;
  }
  .mt-h1 {
    font-size: 1.6rem;
    text-align: left;
  }
  .mt-h2 {
    font-size: 1.4rem;
  }
  .mt-h3 {
    font-size: 1.2rem;
  }
  .mt-head-img {
    margin-top: 2px;
    width: 100%;
    height: 160px;
  }
  .mt-head-below {
    padding: 5px;
  }
  .mt-third {
    padding: 5px;
  }
  .mt-fourth {
    padding: 5px;
    ul {
      padding-top: 10px;
      padding-left: 20px;
      h4 {
        line-height: 32px;
      }
    }
  }
  .mt-table1 {
    margin: 2px 2px;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 2px;
    h3 {
      font-size: 1.2rem;
      font-weight: 550;
    }
  }
  .mt-img-section {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .mt-img-left {
    width: 100%;
  }
  .mt-img-right {
    width: 100%;
    padding-top: 900px;
  }
  .mt-img-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .mt-img-left {
    line-height: 28px;
  }
  .mt-img-left ul {
    padding-left: 8px;
  }
}

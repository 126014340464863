.ab-main-content {
  display: flex;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  letter-spacing: 0.7px;
  background-color: #ffffff;
}
.ab-second-left {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.ab-second-left > h1 {
  font-size: 50px;
  font-weight: bold;
  color: #9d2553;
}
.ab-second-left > p {
  margin-bottom: 20px;
  letter-spacing: 0.4px;
}
.ab-second-right {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.ab-second-right > h2 {
  font-size: 40px;
  margin-bottom: 25px;
}
.sub-head {
  display: flex;
  align-items: bottom;
  gap: 10px;
}
.sub-head > .Icon {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #6eb6fe;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 25px;
}
.style-text > p {
  font-size: 20px;
  font-style: italic;
  letter-spacing: 0.7px;
  line-height: 1.5;
  color: #9d2553;
  margin: 10px;
  padding-left: 45px;
  margin-bottom: 20px;
}
.text-service-btn {
  width: 200px;
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #1a8efd;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.4s ease;
  margin: 4px;
}
.text-service-btn:hover {
  color: #1a8efd;
  background-color: transparent;
  border: 1px solid #1a8efd;
}
.ab-third {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.ab-points {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.sup-content {
  margin-top: 50px;
  padding-bottom: 50px;
  margin-left: 15px;
}
.sup-content > h3 {
  font-size: 45px;
  font-weight: extra-bold;
}
.sup-content > p {
  font-size: 22px;
  font-weight: bold;
  margin-top: -20px;
}
.icon {
  font-size: 48px;
  color: #ffffff;
  background-color: #1a8efd;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab-fifth {
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .ab-main-content {
    flex-direction: column;
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
  .ab-second-left {
    width: 100%;
    padding: 20px;
  }
  .ab-second-left > h1 {
    font-size: 40px;
  }
  .ab-second-left > p {
    margin-bottom: 15px;
    letter-spacing: 0.3px;
  }
  .ab-second-right {
    padding: 20px;
  }
  .ab-second-right > h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .sub-head {
    gap: 5px;
  }
  .sub-head > .Icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 20px;
  }
  .style-text > p {
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.8px;
    line-height: 1.5;
    padding-left: 30px;
    margin: 15px;
    margin-top: 0px;
  }
  .text-service-btn {
    width: 160px;
    padding: 10px 14px;
    font-size: 16px;
  }
  .ab-third {
    padding: 10px;
  }
  /* .ab-points {
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  } */
  /* .sup-content {
    margin-top: 50px;
    padding-bottom: 50px;
    margin-left: 15px;
  } */
  .sup-content > h3 {
    font-size: 28px;
  }
  .sup-content > p {
    font-size: 18px;
    margin-top: -10px;
  }
  .icon {
    font-size: 36px;
    height: 56px;
    width: 56px;
  }
}
@media screen and (max-width: 900px) {
  .ab-third {
    flex-direction: column;
  }
  .ab-points {
    margin-left: 10px;
  }
  .sup-content {
    margin-top: 12px;
    padding-bottom: 12px;
    margin-left: 18px;
    width: 100%;
  }
  .sup-content > h3 {
    font-size: 32px;
  }
  .sup-content > p {
    font-size: 16px;
    margin-top: -5px;
    letter-spacing: 1px;
  }
  .icon {
    font-size: 30px;
    height: 50px;
    width: 60px;
  }
}
